import React from 'react';
import styled from 'styled-components';
import { device } from '../components/base/mediaquery';
import { Button } from '../components/base/button';

const SubscribeForm = styled.form`
    display:flex;
    flex-direction:${props => props.theme.direction.column};
    position:${props => props.theme.position.relative};
    width:calc(100% / 3);
    margin-top:${props => props.theme.spacing.doubleInset};
    margin-bottom:${props => props.theme.spacing.doubleInset};
    justify-content:center;

    @media ${device.laptop} {
        width:100%;
    }

    @media ${device.mobileL} {
		flex-direction:${props => props.theme.direction.column};
	}
`;

const FormGroup = styled.div`
    display:flex;
    flex-direction:${props => props.theme.direction.column};
    width:calc( 100% );

    @media ${device.mobileL} {
        width:calc( 100% );
        margin-left:0;
        margin-right:0;
	}
`;

const Label = styled.label`
    font-size:13px;
    text-transform:uppercase;
    font-weight:bold;
    margin-bottom:15px;
`;

const Input = styled.input`
    margin-bottom:${props => props.theme.spacing.doubleInset};
    border-radius:0;
    border:1px solid ${props => props.theme.colors.defaultTertiary};
    padding:15px 20px;
    height:40px;
    position:relative;

    &:focus {
        outline: none;
    }
`;

const StyledButton = styled(Button).attrs({ as: 'button' })`
    padding:15px 40px;
    border:0;
`;

const SubContainer = styled.div`
    display:flex;
    flex-direction:column;
`;

const Subscribe = () => {
    return (
        <>
            <SubscribeForm className="js-cm-form" id="subForm" action="https://www.createsend.com/t/subscribeerror?description=" method="post" data-id="92D4C54F0FEC16E5ADC2B1904DE9ED1AB3081FF9F5D9C61C787CC8D4EE55B4CCD1FA56A684A910D5E00E513BAB4BC387D06D045A122F0A1B54DA77C8C4D6DC10">
                <SubContainer>
                    <FormGroup>
                        <Label>Full name</Label>
                        <Input aria-label="Name" id="fieldName" maxlength="200" name="cm-name" />
                    </FormGroup>
                    <FormGroup>
                        <Label>Email</Label>
                        <Input autocomplete="Email" aria-label="Email" className="js-cm-email-input qa-input-email" id="fieldEmail" maxlength="200" name="cm-jydtkdk-jydtkdk" required="" type="email" />
                    </FormGroup>
                </SubContainer>
                <StyledButton type="submit" bgColor="secondary" color="defaultSecondary">Subscribe</StyledButton>
            </SubscribeForm>
        </>
    )
}

export default Subscribe;