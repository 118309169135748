import React from 'react';
import styled from 'styled-components';
import FacebookIcon from '../components/icons/facebook';
import InstagramIcon from '../components/icons/instagram';
import { device } from '../components/base/mediaquery';

const Icons = {
    'facebook': FacebookIcon,
    'instagram': InstagramIcon,
}

const Container = styled.div`
    padding-top:${props => props.theme.spacing.doubleInset};
    padding-bottom:${props => props.theme.spacing.doubleInset};
    display:flex;
    flex-direction:${props => props.theme.direction.row};

    @media ${device.laptop} {
        padding-bottom:${props => props.theme.spacing.doubleInset};
    }
`;

const ActionLink = styled.a`
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    transition:${props => props.theme.transition.linear};
    margin-right:10px;
    margin-left:10px;
    background-color:${props => props.theme.colors.secondary};
    width:50px;
    height:50px;
    border-radius:50%;
    padding:0;

    > div {
        min-width: 25px;
        width: 25px;
        max-width: 25px;
        height: 25px;
        min-height: 25px;
        max-height: 25px;
    }

    &:hover {
        svg {
            color:${props => props.theme.colors.primary};
        }
    }
`;

const socialLinks = [
    {
        link: 'https://www.facebook.com/LeukiesAruba',
        title: 'Have fun on Facebook',
        type: 'facebook',
    },
    {
        link: 'https://www.instagram.com/LeukiesAruba',
        title: 'Have fun on Instagram',
        type: 'instagram',
    }
];


const SocialMediaLinks = () => {
    return (
        <Container>
            {socialLinks?.length > 0 && socialLinks.map(({link, title, type }, i) => {
                const Icon = Icons[type] ? Icons[type] : Icons['facebook'];
                return (
                    <ActionLink key={`social-media-icon-${type}-${i}`} href={link} title={title} target="_blank" rel="noopener">
                        <Icon iconColor="defaultSecondary" />
                    </ActionLink>
                )
            })}
        </Container>
    )
}
export default SocialMediaLinks;