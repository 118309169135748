import styled, { css } from 'styled-components';
import { device } from '../../components/base/mediaquery';

export const H1 = styled.h1`
    font-size:${props => props.theme.fontSizes.extraLarge};
    font-weight:${props => props.theme.fontWeight.bold};
    line-height:100%;
    color:${props => props.theme.colors[props.color]};
    letter-spacing:0;
    text-align:${props => props.theme.textAlign.left};

    @media ${device.mobileL} { 
        font-size:36px;
        line-height:57px;
    }
`;

export const H2 = styled(H1).attrs({
    as: "h2"
})`
    font-size:${props => props.theme.fontSizes.medium};
`;

export const H3 = styled(H1).attrs({
    as: "h3"
})`
    font-size:${props => props.theme.fontSizes.medium};
    font-weight:${props => props.theme.fontWeight.regular};
`;

export const H4 = styled(H1).attrs({
    as: "h4"
})`
    font-size:${props => props.theme.fontSizes.small};
    font-weight:${props => props.theme.fontWeight.regular};
`;

export const ParagraphCSS = css`
    font-size:${props => props.theme.fontSizes.extraSmall};
    line-height:${props => props.theme.fontSizes.small};
    color:${props => props.theme.colors[props.color]};
    letter-spacing:0;
    text-align:${props => props.theme.textAlign[props.textAlign]};
    margin-bottom:${props => props.theme.spacing.doubleInset};
`;

export const Paragraph = styled.p`
    ${ParagraphCSS}
`;

export const ULCSS = css`
    padding-left:15px;
    li {
        ${ParagraphCSS}
        margin-bottom:0;
    }
`;

export const UL = styled.ul`
    ${ULCSS};
`;