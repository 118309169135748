import styled from 'styled-components';

export const Button = styled.a`
    background-color:${props => props.theme.colors[props.bgColor]};
    color:${props => props.theme.colors[props.color]};
    font-size:${props => props.theme.fontSizes.extraSmall};
    font-weight:${props => props.theme.fontWeight.regular};
    line-height:${props => props.theme.fontSizes.extraSmall};
    letter-spacing:3px;
    text-align:${props => props.theme.textAlign.center};
    text-decoration:none;
    border-radius:0;
    padding:10px 25px;
    transition:${props => props.theme.transition.linear};
    cursor: pointer;

    &:hover {
        color:${props => props.theme.colors.defaultPrimary};
    }
`;