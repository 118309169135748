import styled from 'styled-components';
import GlobalStyles from '../src/styles/theme/global-styles';
import Theme from '../src/styles/theme';
import { Container, Row , Column } from '../src/components/base/grid';
import { H2 } from '../src/components/base/typography';
import { device } from '../src/components/base/mediaquery';
import Brand from './leukies-logo.png';
import SocialMedia from '../src/components/social-media';
import Subscribe from '../src/components/newsletter';

const Main = styled(Container).attrs({
    as: "main"
})`
	background-color:${props => props.theme.colors.defaultSecondary}; 
	width:100%;
	min-height:100vh;
`;

const StyledColumn = styled(Column)`
	padding-top:${props => props.theme.spacing[props.pt]};
	padding-bottom:${props => props.theme.spacing[props.pb]};
`;

const Logo = styled.img`
	display: block;
	max-width:100%;
	height:auto;
`;

const Title = styled(H2)`
	color:${props => props.theme.colors.defaultPrimary};
	font-size:${props => props.theme.fontSizes.extraSmall};
	letter-spacing:8px;
	text-transform:uppercase;
	font-weight:${props => props.theme.fontWeight.light};
	text-align:center;
	@media ${device.mobileL} {
		font-size:16px;
		letter-spacing:2px;
	}
`;

const SubTitle = styled(Title).attrs({
	as: 'h3'
})`
	font-size:18px;
	border-top:1px solid ${props => props.theme.colors.defaultTertiary};
	padding-top:60px;
	margin-top:60px;
`;

function App() {
	return (
		<Theme>
            <GlobalStyles />
			<Main 
				direction="column" 
				alignItems="center" 
				justifyContent="center"
			>
                <Row direction="column">
					<StyledColumn width="100%" justifyContent="center" pt="doubleInset" pb="doubleInset">
						<Logo src={Brand} alt="Leukies Aruba" />
					</StyledColumn>
					<StyledColumn width="100%" justifyContent="center" direction="column" alignItems="center" pt="doubleInset" pb="doubleInset">
						<Title>Have Fun with us on social media</Title>
						<SocialMedia />
						<SubTitle>Subscribe for more fun</SubTitle>
						<Subscribe />
					</StyledColumn>
				</Row>
            </Main>
        </Theme>
	);
}

export default App;
